<template>
  <div>
    <div class='scroll-row'>
      <div
        v-for='e of columns'
        :key='e.field'
        :style="{
          color: '#2cadff',
          width: e.width ? e.width : 'auto',
        }"
        class='scroll-col'
      >
        {{ e.title }}
      </div>
    </div>
    <vueSeamlessScroll
      ref='seamlessWarp'
      :class-option='classOption'
      :data='formatDataSource'
      style='overflow: hidden'
      @ScrollEnd='isLogout ? null : onScrollEnd()'
    >
      <slot :formatDataSource='formatDataSource' name='scroll-data'>
        <div
          v-for='(e, i) in formatDataSource'
          :key='i'
          :data-index='i'
          :style="i % 2 == 0 ? {} : { background: 'rgba(33, 165, 233,0.12)' }"
          class='scroll-row'
        >
          <div
            v-for='ee of columns'
            :key='ee.field'
            :style="{
              width: ee.width ? ee.width : 'auto',
            }"
            class='scroll-col'
          >
            <slot v-if='ee.slots' :name='`default-${ee.field}`' :row='e'></slot>
            <template v-else>
              {{ e[ee.field] ? `${e[ee.field]}` : '-' }}
            </template
            >
          </div>
        </div>
      </slot>
    </vueSeamlessScroll>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  //组件注册
  components: {
    vueSeamlessScroll: () => import('vue-seamless-scroll'),
  },
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    classOption: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [
        {
          title: '机构',
          field: 'organizationText',
          width: '20%',
        },
        {
          title: '时间',
          field: 'warningTime',
          width: '40%',
        },
        {
          title: '监督类型',
          field: 'analysisItemText',
          width: '20%',
        },
        {
          title: '预警内容',
          field: 'description',
          width: '20%',
        },
      ],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: '',
    },
    isGlobalQuery: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    isAuto: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLogout: false,
      total: [],
      /* 分页参数 */
      ipagination: {
        pageNo: 1,
        maxResults: 10,
        pages: 0,
        total: 0,
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      globalQuery: (state) => state.screenStore.globalQuery,
    }),
    formatDataSource() {
      return this.url ? this.total : this.dataSource;
    },
  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      handler: 'refresh',
    },
    formatDataSource() {
      this.$nextTick(() => {
        if (this.$refs.seamlessWarp) {
          this.$refs.seamlessWarp.$el.onclick = (event) => {
            const e = window.event || event
            const path = event.path || (event.composedPath && event.composedPath())
            if (path) {
              [...path].forEach((dom) => {
                if (dom.className == 'scroll-row') {
                  this.$emit('clickRow', {
                    target: e.target,
                    row: this.formatDataSource[dom.dataset.index],
                  });
                }
              });
            }

          };
        }
      });
    },
    $route: {
      deep: true,
      handler() {
        this.isLogout = this.$route.name == 'login';
      },
    },
  },
  //DOM访问
  mounted() {
    if (this.isAuto) {
      this.getData();
    }
  },
  //保存方法
  methods: {
    refresh(refresh) {
      this.ipagination.pageNo = 1;
      this.getData(refresh);
    },
    async getData(query) {
      try {
        this.$emit('set:value', true);
        const data = await this.$http.get(this.url, {
          ...['pageNo', 'maxResults'].reduce(
            (t, c) => ({ ...t, [c]: this.ipagination[c] }),
            {},
          ),
          ...(this.isGlobalQuery ? this.globalQuery : {}),
          ...query,
          ...this.query,
        });
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        const result = data.data[0];
        if (this.ipagination.pageNo == 1) {
          this.total = result.records;
        } else {
          this.total = this.total.concat(result.records);
        }
        this.ipagination.pages = result.pages;
        this.ipagination.total = result.total;
        this.$emit('getTotal', this.ipagination.total)
        this.$emit('set:value', false);
      } catch (error) {
        this.$emit('set:value', false);
        console.log(error);
      }
    },
    onScrollEnd() {
      if (this.url && this.ipagination.pageNo < this.ipagination.pages) {
        this.ipagination.pageNo += 1;
        this.getData();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.scroll-row {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 8px;

  .scroll-col {
    font-size: 14px;
    font-weight: 400;
    color: #e9faff;
    word-break: break-all;
    // flex: 1;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
